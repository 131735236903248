<template>
  <div class="gettoken-container">
    <el-row class="title-container">
      <el-col :span="20">
        <div class="title-text"> {{ $t('general.Create') }} Access Token </div>
      </el-col>
      <el-col :span="4" class="title-right">
        <el-button
          type="success"
          @click="submitForm('getTokenForm')"
        >
          {{ $t('accessToken.getToken') }}
        </el-button>
      </el-col>
    </el-row>
    <template v-if="tokenCreated"> 
      <div class="savetoken-container">
        <div>Access Token {{ $t('accessToken.created') }}</div>
        <el-row class="savetoken-token">
          <el-col :span="22" class="token-text">
            {{ token }}
          </el-col>
          <el-col :span="2" class="button-container">
            <el-button class="btn-copy" type="info" plain circle @click="copy">
              <i class="el-icon-document-copy" />
            </el-button>
          </el-col> 
        </el-row>
        <div class="savetoken-notice">*{{ $t('accessToken.saveWarning') }}</div>
      </div>
    </template>
    <div class="form-container">
      <el-form
        ref="getTokenForm"
        :model="getTokenForm"
        :rules="rules"
        label-position="top"
        class="token-form"
      >
        <el-form-item :label="$t(`accessToken.fieldName`)" prop="name">
          <el-input v-model="getTokenForm.name" />
        </el-form-item>
        <el-form-item :label="$t(`accessToken.fieldExpired`)" prop="expired_date">
          <el-date-picker 
            v-model="getTokenForm.expired_date" 
            placement="bottom-start"
            style="width: 100%;"
            @change="getActiveDate"
          />
        </el-form-item>
        <el-form-item :label="$t(`accessToken.fieldScope`)" prop="permission">
          <el-checkbox-group v-model="getTokenForm.permission">
            <el-checkbox class="limit-checkbox" label="GET" name="permission"> {{ $t('accessToken.permissionGet') }} </el-checkbox>
            <el-checkbox class="limit-checkbox" label="POST" name="permission"> {{ $t('accessToken.permissionPost') }} </el-checkbox>
            <el-checkbox class="limit-checkbox" label="DELETE" name="permission"> {{ $t('accessToken.permissionDelete') }} </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
    </div>
    <div class="table-container">
      <div class="table-title"> {{ $t('general.Active') }} Access Token </div>
      <el-table
        :data="tableData"
        stripe
      >
        <el-table-column
          v-for="(item, index) in fields"
          :key="index"
          :prop="item.prop"
          :label="$t(`accessToken.${item.label}`)"
          align="center"
        />
        <el-table-column
          :label="$t('general.Edit')"
          width="100px"
        >
          <template slot-scope="scope">
            <el-button
              type="danger"
              icon="el-icon-delete-solid"
              circle
              plain
              size="mini"
              @click="handleDeleteIcon(scope.row)"
            />
          </template>
        </el-table-column>
        <template slot="empty">
          <el-empty :description="$t('general.NoData')" />
        </template>
      </el-table>
    </div>
    <el-dialog
      :title="$t('general.Warning')"
      :visible.sync="deletedialogVisible"
      width="40%"
    >
      <div>{{ $t('Notify.confirmDeleteSth', { name: deletedtokenName }) }}</div>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" plain @click="fetchDelete">{{ $t('general.Delete') }}</el-button>
        <el-button type="info" @click="deletedialogVisible = false">{{ $t('general.Cancel') }}</el-button>
      </span>
    </el-dialog>
  </div>
  
</template>

<script>
import dayjs from 'dayjs'
import { getToken, postToken, deleteToken } from '@/api/accessToken'
import { SuccessMessage } from '@/Mixins'

const defaultForm = () => ({
  name: '',
  expired_date: '',
  permission: ['GET']
})

export default {
  name: 'GetToken',
  mixins: [SuccessMessage],
  data () {
    return {
      getTokenForm: defaultForm(),
      formatedate: '',
      rules: {
        name: [
          { required: true, message: this.$t('Notify.Name'), trigger: 'blur' }
        ],
        expired_date: [
          { required: true, message: this.$t('Notify.Date'), trigger: 'blur' }
        ],
        permission: [
          { type: 'array', required: true, message: this.$t('Notify.permission'), trigger: 'change' }
        ]
      },
      fields: [
        {
          prop: 'name',
          label: 'fieldName'
        },
        {
          prop: 'create_at',
          label: 'fieldActive'
        },
        {
          prop: 'expired_date',
          label: 'fieldExpired'
        }
      ],
      tableData: [],
      tokenCreated: false,
      copyData: '',
      token: '',
      deletedialogVisible: false,
      deletedtokenName: '',
      deletedId: ''
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData () {
      this.fetchGetToken()
    },
    async fetchGetToken() {
      try {
        const res = await getToken()
        this.tableData = res.data.map(item => {
          const newObj = {
            ...item,
            expired_date: dayjs(item.expired_date).format('YYYY-MM-DD'),
            create_at: dayjs(item.create_at).format('YYYY-MM-DD')
          }
          return newObj
        })
      } catch (error) {
        console.log('getToken-error: ', error)
      }
      
    },
    async fetchPostToken() {
      const newObj = {
        name: this.getTokenForm.name,
        expired_date: this.formatedate,
        permission: this.getTokenForm.permission
      }
      try {
        const res = await postToken(newObj)
        this.token = res.data.token
        this.tokenCreated = true
        this.showSuccessMessage(this.$t('Notify.Added'))
      } catch (error) {
        console.error(error)
      }
      this.getTokenForm = defaultForm()
      this.loadData()
    },
    async fetchDeleteToken(data) {
      try {
        await deleteToken(data)
        this.showSuccessMessage(this.$t('Notify.Deleted'))
      } catch (error) {
        console.error(error)
      }
      this.loadData()
    },
    async fetchDelete() {
      if (this.deletedId) {
        try {
          await deleteToken(this.deletedId)
          this.showSuccessMessage(this.$t('Notify.Deleted'))
        } catch (error) {
          console.error(error)
        }
      }
      this.deletedId = ''
      this.deletedtokenName = ''
      this.deletedialogVisible = false
      this.loadData()
    },
    
    getActiveDate() {
      const defaultDate = this.getTokenForm.expired_date
      this.UTCtoLocalTime(defaultDate)
    },
    UTCtoLocalTime(utcTime) {
      const localTime = dayjs
        .utc(utcTime)
        .local()
        .format('YYYY-MM-DD HH:mm:ss')
      if (utcTime === 'Invalid date') {
        return '-'
      }
      this.formatedate = localTime
    },
    submitForm(getTokenForm) {
      this.$refs[getTokenForm].validate((valid) => {
        if (valid) {
          this.fetchPostToken()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    copy() {
      const copyData = this.token

      if (navigator.clipboard) {
        navigator.clipboard.writeText(copyData)
      } else {
        const textArea = document.createElement('textarea')
        textArea.value = copyData
        document.body.appendChild(textArea)
        textArea.focus()
        textArea.select()

        return new Promise((res, rej) => {
          document.execCommand('copy') ? res() : rej
          textArea.remove()
        })
      }

      const message = this.$t('general.Copy')
      this.showSuccessMessage(message)
    },
    handleDeleteIcon(row) {
      this.deletedialogVisible = true
      this.deletedtokenName = row.name
      this.deletedId = row.id
    }
  }
}

</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.gettoken-container{
  width: 100%;
  padding: 0 20px;
  .title-container{
      margin: 10px 0;
      .title-text{
        font-size: 20px;
        line-height: 2;
        text-align: left;
      }
      .title-right{
        text-align: end;
        .el-button {
          margin-bottom: 5px;
          padding: 10px 50px;
          margin-bottom: 5px;
        }
      }
    }

  .savetoken-container{
    padding: 20px 30px;
    text-align: left;
    font-size: 14px;
    background-color: $bg_header;
    border-radius: 10px;
    margin-bottom: 10px;
    font-weight: 600;
    color: $text;
    .savetoken-token{
      background-color: $bg_site;
      border-radius: 10px;
      padding-left: 10px;
      margin: 10px -5px;
      box-shadow: 0 0 10px rgba(65, 61, 61, 0.6);
      .token-text{
        line-height: 40px;
      }
      .button-container{
        text-align: right;
      }
      .el-button{
        background-color: transparent;
        border: none;
      }

      .el-button:hover{
        color: rgba(65, 61, 61);
      }
    }

    .savetoken-notice{
      color: $danger;
      font-size: 13px;
    }
  }
  .form-container{
    background-color: #fff;
    .token-form{
      padding: 50px 0;
      margin: 0 auto;
      width: 600px;
    }
    .limit-checkbox{
      display: block;
      text-align: left;
      padding-left: 20px;
    }
  }

  .table-container{
    border-top: 2px solid #c6bdc2;
    padding-top: 10px;

    .table-title{
      text-align: left;
      font-size: 20px;
      line-height: 2;
      margin: 10px 0;
    }
  }
}
</style>
